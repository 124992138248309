import React from 'react'
import { Link } from 'gatsby'
import { Card, Animated, getAnimationVariant } from '@roar/components'
import { mapperWithFunction } from '../common/utils'
import { Text } from '@roar/components'
import { formatImage } from './ImageWrapper'

const CARD_MAPPER = {
  name: ['name'],
  description: ['description', 'childMarkdownRemark', 'rawMarkdownBody'],
  slug: ['slug'],
  image: (value) => formatImage(value, true),
}

const ResponsibleTravelWrapper = (props) => {
  const { description, slug, name, image } = mapperWithFunction(props, CARD_MAPPER)

  return (
    <Link to={`/responsible-travel/${slug}`}>
      <Animated shouldAnimate={true} variants={getAnimationVariant(props.indexInArray)}>
        <Card
          alignment="left"
          variant="standard"
          image={image}
          meta={null}
          headline={name}
          description={<Text>{description}</Text>}
          mb={[2, 2, 4]}
        />
      </Animated>
    </Link>
  )
}

export default ResponsibleTravelWrapper
